import { Theme } from "@mui/material";
import { Components, ComponentsProps } from "@mui/material/styles";

import * as colors from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

import { ARIAL_BLACK_FONT_FAMILY } from "../constants/typography";

export const getMuiTabsComponent = (theme: Theme): Components["MuiTabs"] => ({
  defaultProps: getTestAutomationProps("tabs") as ComponentsProps["MuiTabs"],
  styleOverrides: {
    root: {
      boxShadow: `inset 0 -2px 0 0 ${colors.COLOR_GREYSCALE_BLACK_8}`,
    },
    indicator: {
      display: "none",
      backgroundColor: colors.COLOR_PRIMARY_BLACK,
    },
  },
});

export const getMuiTabComponent = (theme: Theme): Components["MuiTab"] => ({
  defaultProps: getTestAutomationProps("tab") as ComponentsProps["MuiTab"],
  styleOverrides: {
    root: {
      whiteSpace: "nowrap",
      position: "relative",
      padding: theme.spacing(1.5, 2),
      minWidth: "unset",
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular as number,
      lineHeight: "24px",
      letterSpacing: "0.03em",
      color: colors.COLOR_GREYSCALE_BLACK_75,
      height: 48,
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2),
        fontSize: 18,
        minWidth: "unset",
        height: 56,
      },
      "&:hover": {
        boxShadow: `inset 0 -2px 0 0 ${colors.COLOR_PRIMARY_BLACK}`,
      },
      "&:active": {
        color: colors.COLOR_PRIMARY_BLACK,
        boxShadow: `inset 0 -2px 0 0 ${colors.COLOR_PRIMARY_BLACK}`,
      },
      "&.Mui-selected": {
        fontFamily: ARIAL_BLACK_FONT_FAMILY,
        color: colors.COLOR_PRIMARY_BLACK,
        fontWeight: theme.typography.fontWeightBold,
        boxShadow: `inset 0 -2px 0 0 ${colors.COLOR_PRIMARY_BLACK}`,
        "& .MuiTab-wrapper": { marginTop: -2 },
      },
      "&:disabled": {
        color: colors.COLOR_GREYSCALE_BLACK_54,
      },
      "&.Mui-focusVisible, &:focus-visible": {
        boxShadow: `inset 0 0 0 2px ${colors.COLOR_FOCUSED}`,
      },
    },
    textColorInherit: {
      color: colors.COLOR_GREYSCALE_BLACK_75,
      opacity: 1,
    },
  },
});
