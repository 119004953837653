import { Theme } from "@mui/material";
import { Components, ComponentsProps } from "@mui/material/styles";

import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiTypographyComponent = (
  theme: Theme
): Components["MuiTypography"] => ({
  defaultProps: {
    ...(getTestAutomationProps("typography") as ComponentsProps["MuiTypography"]),
    variantMapping: {
      h1Regular: "h1",
      h1Medium: "h1",
      h1Small: "h1",
      h1ExtraSmall: "h1",
    },
  },
});
