import React from "react";

import { Theme, Box } from "@mui/material";
import { Components } from "@mui/material/styles";
import { CSSProperties } from "@mui/styled-engine";

import { COLOR_BLACK } from "~/theme/constants/colors";
import { TypographyBodyClass } from "~/theme/constants/typography";
import { Down24 } from "~/theme/icons";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

const ExpandIcon = () => {
  return (
    <Box sx={{ height: 24, width: 24 }}>
      <Down24 />
    </Box>
  );
};

export const getMuiAccordionSummaryComponent = (
  theme: Theme
): Components["MuiAccordionSummary"] => {
  const formatMedia = getFormatMedia(theme);
  return {
    defaultProps: {
      expandIcon: <ExpandIcon />,
      ...getTestAutomationProps("summary"),
    },
    styleOverrides: {
      root: {
        paddingTop: theme.spacing(3.25),
        paddingBottom: theme.spacing(3.25),
        [formatMedia.BREAKPOINT_TABLET]: {
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
        },
        paddingRight: theme.spacing(2),
        paddingLeft: 0,
        alignItems: "start",
      },
      content: {
        margin: 0,
        paddingRight: theme.spacing(2.625),
        "& .MuiTypography-root, &": {
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.ACCORDION_SMALL}`
          ] as CSSProperties),
          [formatMedia.BREAKPOINT_TABLET]: {
            ...(theme.typography.body1[
              `&.${TypographyBodyClass.ACCORDION_LARGE}`
            ] as CSSProperties),
          },
        },
        "&.Mui-expanded .MuiTypography-root, &.Mui-expanded": {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      expandIconWrapper: {
        color: COLOR_BLACK,
        paddingTop: theme.spacing(0.25),

        [formatMedia.BREAKPOINT_TABLET]: {
          paddingTop: theme.spacing(0.5),
        },
      },
    },
  };
};
