import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import {
  COLOR_BLACK,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_GREYSCALE_BLACK_8,
} from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

import { FontWeights } from "../constants/typography";

export const BREADCRUMBS_TYPOGRAPHY = {
  height: 24,
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: FontWeights.FONT_WEIGHT_REGULAR,
  lineHeight: "24px",
  letterSpacing: "0.02em",
};

export const getMuiBreadcrumbsComponent = (
  theme: Theme
): Components["MuiBreadcrumbs"] => ({
  defaultProps: {
    separator: "/",
    ...getTestAutomationProps("breadcrumbs"),
  },
  styleOverrides: {
    root: {
      "&.withBack .MuiBreadcrumbs-li:first-of-type + .MuiBreadcrumbs-separator": {
        position: "relative",
        color: "transparent",
        width: 17,
        "&:after": {
          content: "''",
          position: "absolute",
          top: 4,
          left: 0,
          bottom: 4,
          width: 9,
          borderRightWidth: 1,
          borderRightStyle: "solid",
          borderRightColor: COLOR_GREYSCALE_BLACK_8,
        },
      },
    },
    li: {
      "& > *:focus:before": {
        display: "none",
      },
      "& > *, & > *:hover, & > *:active, & > *:focus": {
        padding: 0,
        color: COLOR_BLACK,
        margin: theme.spacing(1, 0),
        backgroundColor: "transparent",
        ...BREADCRUMBS_TYPOGRAPHY,
      },
      "& > *:hover": {
        color: COLOR_GREYSCALE_BLACK_75,
        textDecoration: "underline",
      },
      "& > *:active": {
        color: COLOR_BLACK,
        textDecoration: "underline",
      },
      "&:last-child > *": {
        textDecoration: "none",
      },
    },
    separator: {
      ...BREADCRUMBS_TYPOGRAPHY,
      textAlign: "center",
      margin: theme.spacing(0, 1),
      width: 4,
    },
  },
});
