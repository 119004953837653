import { Theme } from "@mui/material";
import { ComponentsProps, Components } from "@mui/material/styles";

import { COLOR_GREYSCALE_BLACK_25 } from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiBackdropComponent = (
  theme: Theme
): Components["MuiBackdrop"] => ({
  defaultProps: getTestAutomationProps("backdrop") as ComponentsProps["MuiBackdrop"],
  styleOverrides: {
    root: {
      backgroundColor: COLOR_GREYSCALE_BLACK_25,
    },
  },
});
