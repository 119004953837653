import React from "react";

import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import { getTestAutomationProps } from "~/utils/testUtils";

import {
  COLOR_BLACK,
  COLOR_FOCUSED,
  COLOR_GREYSCALE_BLACK_25,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_GREYSCALE_BLACK_8,
} from "../constants/colors";
import { CheckboxChecked24, Checkbox24, CheckboxIndeterminate24 } from "../icons";

export const getMuiCheckboxComponent = (theme: Theme): Components["MuiCheckbox"] => {
  const CHECKBOX_COLOR = {
    "&, &:hover, &:focus, &:active": {
      opacity: 1,
      backgroundColor: "white !important",
    },
    "&.Mui-disabled	": {
      opacity: 1,
      color: COLOR_GREYSCALE_BLACK_25,
      backgroundColor: "transparent !important",
      "&:not(.Mui-checked	)::before": {
        content: "''",
        position: "absolute",
        zIndex: -1,
        top: 4,
        right: 4,
        bottom: 4,
        left: 4,
        backgroundColor: COLOR_GREYSCALE_BLACK_8,
      },
    },
    "&, &:focus": {
      color: COLOR_BLACK,
    },
    "&:hover, &:active": {
      color: COLOR_GREYSCALE_BLACK_75,
    },
  };

  return {
    defaultProps: {
      color: "primary",
      icon: <Checkbox24 />,
      checkedIcon: <CheckboxChecked24 />,
      indeterminateIcon: <CheckboxIndeterminate24 />,
      ...getTestAutomationProps("checkbox"),
    },
    styleOverrides: {
      root: {
        margin: theme.spacing(1),
        padding: 0,
        height: 20,
        width: 20,
        "&.Mui-focusVisible:focus-within::before": {
          content: "''",
          display: "block",
          position: "absolute",
          top: -2,
          bottom: -2,
          left: -2,
          right: -2,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: COLOR_FOCUSED,
        },
      },
      colorPrimary: CHECKBOX_COLOR,
      colorSecondary: CHECKBOX_COLOR,
    },
  };
};
