"use client";
import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";

import {
  getMuiAccordionComponent,
  getMuiAccordionDetailsComponent,
  getMuiAccordionSummaryComponent,
} from "./Accordion";
import { getMuiAppBarComponent } from "./AppBar";
import { getMuiBackdropComponent } from "./Backdrop";
import { getMuiBreadcrumbsComponent } from "./Breadcrumbs";
import { getMuiButtonComponent } from "./Button";
import { getMuiButtonGroupComponent } from "./ButtonGroup";
import { getMuiCheckboxComponent } from "./Checkbox";
import { getMuiChipComponent } from "./Chip";
import { THEME_BREAKPOINTS } from "./constants/breakpoints";
import {
  COLOR_ERROR,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
} from "./constants/colors";
import { getTypographyOptions } from "./constants/typography";
import { getMuiContainerComponent } from "./Container";
import { getMuiDrawerComponent } from "./Drawer";
import { getMuiFormHelperTextComponent } from "./FormHelperText";
import { getMuiGridComponent } from "./Grid";
import { getMuiIconButtonComponent } from "./IconButton";
import { getMuiInputBaseComponent } from "./InputBase";
import { getMuiInputLabelComponent } from "./InputLabel";
import { getMuiLinkComponent } from "./Link";
import { getMuiMenuComponent, getMuiMenuItemComponent } from "./Menu";
import { getMuiOutlinedInputComponent } from "./OutlinedInput";
import { getMuiPopoverComponent } from "./Popover";
import { getMuiRadioComponent } from "./Radio";
import { getMuiSelectComponent } from "./Select";
import { getMuiSwitchComponent } from "./Switch";
import { getMuiTabComponent, getMuiTabsComponent } from "./Tabs";
import { getMuiTextFieldComponent } from "./TextField";
import { getMuiToolbarComponent } from "./Toolbar";
import { getMuiTooltipComponent } from "./Tooltip";
import { getMuiTypographyComponent } from "./Typography";

const defaultTheme = createTheme({
  palette: {
    error: {
      main: COLOR_ERROR,
    },
    primary: {
      main: COLOR_PRIMARY_BLACK,
    },
    background: {
      default: COLOR_PRIMARY_WHITE,
    },
  },
  breakpoints: THEME_BREAKPOINTS,
});

const componentsTheme = createTheme({
  typography: getTypographyOptions(defaultTheme),
  components: {
    MuiLink: getMuiLinkComponent(defaultTheme),
    MuiBackdrop: getMuiBackdropComponent(defaultTheme),
    MuiBreadcrumbs: getMuiBreadcrumbsComponent(defaultTheme),
    MuiButton: getMuiButtonComponent(defaultTheme),
    MuiIconButton: getMuiIconButtonComponent(defaultTheme),
    MuiButtonGroup: getMuiButtonGroupComponent(defaultTheme),
    MuiTextField: getMuiTextFieldComponent(defaultTheme),
    MuiInputBase: getMuiInputBaseComponent(defaultTheme),
    MuiOutlinedInput: getMuiOutlinedInputComponent(defaultTheme),
    MuiInputLabel: getMuiInputLabelComponent(defaultTheme),
    MuiFormHelperText: getMuiFormHelperTextComponent(defaultTheme),
    MuiSelect: getMuiSelectComponent(defaultTheme),
    MuiContainer: getMuiContainerComponent(defaultTheme),
    MuiGrid: getMuiGridComponent(defaultTheme),
    MuiAccordion: getMuiAccordionComponent(),
    MuiAccordionSummary: getMuiAccordionSummaryComponent(defaultTheme),
    MuiAccordionDetails: getMuiAccordionDetailsComponent(defaultTheme),
    MuiTabs: getMuiTabsComponent(defaultTheme),
    MuiTab: getMuiTabComponent(defaultTheme),
    MuiMenu: getMuiMenuComponent(defaultTheme),
    MuiMenuItem: getMuiMenuItemComponent(defaultTheme),
    MuiCheckbox: getMuiCheckboxComponent(defaultTheme),
    MuiRadio: getMuiRadioComponent(defaultTheme),
    MuiSwitch: getMuiSwitchComponent(defaultTheme),
    MuiChip: getMuiChipComponent(defaultTheme),
    MuiPopover: getMuiPopoverComponent(defaultTheme),
    MuiAppBar: getMuiAppBarComponent(defaultTheme),
    MuiToolbar: getMuiToolbarComponent(defaultTheme),
    MuiTooltip: getMuiTooltipComponent(defaultTheme),
    MuiTypography: getMuiTypographyComponent(defaultTheme),
    MuiDrawer: getMuiDrawerComponent(defaultTheme),
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
  },
});

export const THEME = createTheme(deepmerge(defaultTheme, componentsTheme));
