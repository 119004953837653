import { Theme } from "@mui/material";
import { ComponentsProps, Components } from "@mui/material/styles";

import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiToolbarComponent = (theme: Theme): Components["MuiToolbar"] => {
  const formatMedia = getFormatMedia(theme);

  return {
    defaultProps: getTestAutomationProps("toolbar") as ComponentsProps["MuiToolbar"],
    styleOverrides: {
      gutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),

        [formatMedia.BREAKPOINT_TABLET]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },

        [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },

        [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
        },
      },
    },
  };
};
