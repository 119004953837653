import React from "react";

import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import {
  COLOR_BLACK,
  COLOR_FOCUSED,
  COLOR_GREYSCALE_BLACK_5,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_GREYSCALE_BLACK_8,
} from "../constants/colors";
import { FontWeights } from "../constants/typography";
import { CloseTiny24 } from "../icons";

export enum ChipType {
  HASHTAG = "hashtag",
  PLP = "plp",
  SEARCH = "search",
  TAG = "tag",
  MEDIATAG = "mediaTag",
}

export const TAG_FONT = {
  desktop: {
    fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
    fontSize: 12,
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  mobile: {
    fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
    fontSize: 11,
    lineHeight: "20px",
    letterSpacing: "0.03em",
  },
};

export const getMuiChipComponent = (theme: Theme): Components["MuiChip"] => {
  const formatMedia = getFormatMedia(theme);

  const CHIP_COLOR = {
    "&, &:hover, &:focus": {
      backgroundColor: COLOR_GREYSCALE_BLACK_5,
      color: COLOR_BLACK,
    },
    "&:active": {
      backgroundColor: COLOR_GREYSCALE_BLACK_8,
    },
    "&:hover": {
      color: COLOR_GREYSCALE_BLACK_75,
    },
  };

  const DELETE_ICON_STYLE = {
    outline: "none",
    border: "none",
    margin: 0,
    marginRight: -theme.spacing(1),
    "&, &:hover, &:active, &:focus, .Mui-disabled	": {
      color: "inherit",
    },
    "&:hover": {
      backgroundColor: COLOR_GREYSCALE_BLACK_5,
    },
    "&:active": {
      backgroundColor: COLOR_GREYSCALE_BLACK_8,
    },
    "&.Mui-disabled	": {
      opacity: 0.5,
      border: "none",
    },
  };

  return {
    defaultProps: {
      deleteIcon: <CloseTiny24 />,
      ...getTestAutomationProps("chip"),
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),

        ...CHIP_COLOR,
        ...theme.typography.body1,

        "&:focus, &:focus-within": {
          outline: `${COLOR_FOCUSED} solid 2px`,
        },

        [`&.${ChipType.PLP}`]: {
          ...theme.typography.body2,
          height: 32,
        },

        [`&.${ChipType.SEARCH}`]: {
          ...theme.typography.body1,
          fontWeight: theme.typography.fontWeightMedium,
          height: 40,
        },

        [`&.${ChipType.HASHTAG}`]: {
          ...theme.typography.body1,
          height: 36,
        },

        [`&.${ChipType.TAG}`]: {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          height: 20,
          ...TAG_FONT.mobile,
          [formatMedia.BREAKPOINT_DESKTOP]: {
            height: 24,
            ...TAG_FONT.desktop,
          },
        },

        [`&.${ChipType.MEDIATAG}`]: {
          borderRadius: 12,
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          height: 20,
          ...TAG_FONT.mobile,
          [formatMedia.BREAKPOINT_DESKTOP]: {
            height: 24,
            ...TAG_FONT.desktop,
          },
        },
      },
      colorPrimary: CHIP_COLOR,
      colorSecondary: CHIP_COLOR,
      deletable: CHIP_COLOR,
      deletableColorPrimary: CHIP_COLOR,
      deletableColorSecondary: CHIP_COLOR,
      clickable: CHIP_COLOR,
      clickableColorPrimary: CHIP_COLOR,
      clickableColorSecondary: CHIP_COLOR,

      deleteIcon: DELETE_ICON_STYLE,
      deleteIconColorPrimary: DELETE_ICON_STYLE,
      deleteIconColorSecondary: DELETE_ICON_STYLE,
      icon: {
        marginLeft: -theme.spacing(0.5),
        marginRight: theme.spacing(0.75),
      },
      label: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  };
};
