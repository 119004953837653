import { Theme } from "@mui/material";
import { Components, ComponentsProps } from "@mui/material/styles";

import { COLOR_GREYSCALE_BLACK_15 } from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiPopoverComponent = (theme: Theme): Components["MuiPopover"] => ({
  defaultProps: getTestAutomationProps("popover") as ComponentsProps["MuiPopover"],
  styleOverrides: {
    root: {
      zIndex: 999,
    },
    paper: {
      borderRadius: 0,
      boxShadow: `${theme.spacing(0, 1, 3)} ${COLOR_GREYSCALE_BLACK_15}`,
    },
  },
});
