import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import { getTestAutomationProps } from "~/utils/testUtils";

import { COLOR_BLACK, COLOR_FOCUSED, COLOR_WHITE } from "../constants/colors";

export const getMuiSwitchComponent = (theme: Theme): Components["MuiSwitch"] => {
  const TRACK_SIZE = {
    width: "36px",
    height: "20px",
    borderRadius: "20px",
    padding: 0,
  };

  const SWITCH_BASE_SIZE = {
    padding: 0,
    width: TRACK_SIZE.height,
    height: TRACK_SIZE.height,
    borderRadius: "50%",
  };

  return {
    defaultProps: {
      color: "primary",
      ...getTestAutomationProps("switch"),
    },
    styleOverrides: {
      root: {
        ...TRACK_SIZE,
        opacity: "1 !important",
        margin: theme.spacing(1),
        overflow: "visible",

        "&.Mui-focusVisible::before": {
          display: "none",
          content: "''",
          position: "absolute",
          top: -4,
          bottom: -4,
          left: -4,
          right: -4,
          border: `2px solid ${COLOR_FOCUSED}`,
          borderRadius: TRACK_SIZE.borderRadius,
        },

        "&.Mui-focusVisible:focus-within::before": {
          display: "block",
        },
      },
      track: {
        ...TRACK_SIZE,
        backgroundColor: COLOR_BLACK,
        opacity: 0.5,

        ".MuiSwitch-switchBase.Mui-checked + &": {
          opacity: 1,
        },
        ".MuiSwitch-root:hover &": {
          opacity: 0.75,
        },
        ".MuiSwitch-root:active .MuiSwitch-switchBase:not(.Mui-disabled) + &": {
          opacity: 1,
        },
        ".MuiSwitch-root:active .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + &":
          {
            opacity: 0.5,
          },
        ".MuiSwitch-switchBase.Mui-disabled + &": {
          opacity: 0.25,
        },
      },
      switchBase: {
        ...SWITCH_BASE_SIZE,
        opacity: "1 !important",

        "&, &:hover, &:active": {
          backgroundColor: "transparent",
        },

        "&.Mui-checked": {
          "&, &:hover, &:active": {
            backgroundColor: "transparent",
          },

          transform: `translateX(calc(${TRACK_SIZE.width} - 100%))`,
        },
      },
      input: {
        top: 0,
        left: 0,
        ...SWITCH_BASE_SIZE,
      },
      thumb: {
        backgroundColor: COLOR_WHITE,
        boxShadow: "unset",
        width: 16,
        height: 16,
        border: "none",
      },
    },
  };
};
