import { Theme } from "@mui/material";
import { Components, ComponentsProps } from "@mui/material/styles";

import {
  COLOR_BLACK,
  COLOR_ERROR,
  COLOR_GREYSCALE_NTA_BLACK_75,
  COLOR_GREYSCALE_BLACK_8,
} from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiOutlinedInputComponent = (
  theme: Theme
): Components["MuiOutlinedInput"] => ({
  defaultProps: getTestAutomationProps(
    "outlined-input"
  ) as ComponentsProps["MuiOutlinedInput"],
  styleOverrides: {
    root: {
      borderRadius: 0,
      "& legend": {
        maxWidth: 0,
      },
      "&.Mui-focused:not(&.Mui-error) fieldset": {
        borderColor: `${COLOR_BLACK} !important`,
      },
      "&:not(&.Mui-disabled):hover fieldset": {
        borderColor: `${COLOR_GREYSCALE_NTA_BLACK_75} !important`,
      },
      "&:not(&.Mui-disabled):hover fieldset, &.Mui-error fieldset": {
        borderWidth: 2,
      },
      "&.Mui-error fieldset": {
        borderColor: COLOR_ERROR,
      },
      "&.Mui-disabled fieldset": {
        borderWidth: 0,
        backgroundColor: COLOR_GREYSCALE_BLACK_8,
      },
    },
    input: {
      color: COLOR_BLACK,
      padding: "12px 16px",
      boxSizing: "inherit",
    },
  },
});
