import { Theme } from "@mui/material";
import { AccordionDetailsProps } from "@mui/material/AccordionDetails";
import { Components } from "@mui/material/styles";
import { CSSProperties } from "@mui/styled-engine";

import { TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiAccordionDetailsComponent = (
  theme: Theme
): Components["MuiAccordionDetails"] => {
  const formatMedia = getFormatMedia(theme);
  return {
    defaultProps: getTestAutomationProps("details") as AccordionDetailsProps,
    styleOverrides: {
      root: {
        padding: 0,
        paddingBottom: theme.spacing(4),
        flexDirection: "column",

        "& .MuiTypography-root, &": {
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.ARTICLE_SMALL}`
          ] as CSSProperties),
          [formatMedia.BREAKPOINT_TABLET]: {
            ...(theme.typography.body1[
              `&.${TypographyBodyClass.ARTICLE_LARGE}`
            ] as CSSProperties),
          },
        },
      },
    },
  };
};
