import { Theme } from "@mui/material";
import { ComponentsProps, Components } from "@mui/material/styles";

import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiMenuComponent = (theme: Theme): Components["MuiMenu"] => ({
  defaultProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    ...getTestAutomationProps("menu"),
  },
});

export const getMuiMenuItemComponent = (
  theme: Theme
): Components["MuiMenuItem"] => ({
  defaultProps: {
    disableTouchRipple: true,
    disableRipple: true,
    ...(getTestAutomationProps("menu-item") as ComponentsProps["MuiMenuItem"]),
  },
  styleOverrides: {
    root: {
      whiteSpace: "normal",
      overflowWrap: "break-word",
    },
  },
});
