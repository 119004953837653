import { Theme } from "@mui/material";
import { ComponentsProps, Components } from "@mui/material/styles";

import {
  COLOR_BLACK,
  COLOR_BUTTON_ACTIVE,
  COLOR_BUTTON_HOVER,
  COLOR_FOCUSED,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
  COLOR_WHITE,
} from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiIconButtonComponent = (
  theme: Theme
): Components["MuiIconButton"] => ({
  defaultProps: {
    disableRipple: true,
    disableTouchRipple: true,
    disableFocusRipple: true,
    ...(getTestAutomationProps("icon-button") as ComponentsProps["MuiIconButton"]),
  },
  styleOverrides: {
    root: {
      width: 40,
      height: 40,
      padding: theme.spacing(1),
      borderRadius: 0,
      color: COLOR_PRIMARY_BLACK,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: COLOR_BUTTON_HOVER,
      },
      "&:active": {
        backgroundColor: COLOR_BUTTON_ACTIVE,
      },
      "&.Mui-disabled": {
        opacity: 0.5,
        color: COLOR_PRIMARY_BLACK,
        backgroundColor: "transparent",
      },
      "&.Mui-focusVisible:focus:before": {
        content: "''",
        display: "block",
        position: "absolute",
        top: -6,
        bottom: -6,
        left: -6,
        right: -6,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: COLOR_FOCUSED,
      },
    },
    colorPrimary: {
      color: COLOR_WHITE,
      backgroundColor: COLOR_PRIMARY_BLACK,
      width: 56,
      height: 56,
      "&.MuiIconButton-sizeSmall": {
        width: 48,
        height: 48,
      },
      "&:hover": {
        backgroundColor: COLOR_GREYSCALE_BLACK_75,
      },
      "&:active": {
        backgroundColor: COLOR_PRIMARY_BLACK,
      },
      "&.Mui-disabled": {
        opacity: 0.25,
        color: COLOR_PRIMARY_WHITE,
        backgroundColor: COLOR_PRIMARY_BLACK,
      },
    },
    colorSecondary: {
      color: COLOR_BLACK,
      backgroundColor: COLOR_WHITE,
      width: 56,
      height: 56,
      "&.MuiIconButton-sizeSmall": {
        width: 48,
        height: 48,
      },
      "&:after": {
        content: "''",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: COLOR_BLACK,
      },
      "&:hover": {
        borderWidth: 0,
        borderColor: COLOR_PRIMARY_BLACK,
        color: COLOR_PRIMARY_BLACK,
        backgroundColor: COLOR_BUTTON_HOVER,
      },
      "&:active": {
        borderWidth: 0,
        borderColor: COLOR_PRIMARY_BLACK,
        color: COLOR_PRIMARY_BLACK,
        backgroundColor: COLOR_BUTTON_ACTIVE,
      },
      "&.Mui-disabled": {
        opacity: 0.5,
        borderWidth: 0,
        color: COLOR_PRIMARY_BLACK,
        borderColor: COLOR_PRIMARY_BLACK,
      },
    },
  },
});
