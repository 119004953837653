import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import { COLOR_BLACK } from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiInputLabelComponent = (
  theme: Theme
): Components["MuiInputLabel"] => ({
  defaultProps: {
    shrink: true,
    disableAnimation: true,
    ...getTestAutomationProps("input-label"),
  },
  styleOverrides: {
    root: {
      color: `${COLOR_BLACK} !important`,
    },
    animated: {
      transition: "none",
    },
    outlined: {
      "&.MuiInputLabel-shrink": {
        position: "inherit",
        transform: "none",
        paddingBottom: 4,
        ...theme.typography.body2,
      },
    },
  },
});
