import { Theme } from "@mui/material";
import { ComponentsProps, Components } from "@mui/material/styles";

import { COLOR_BLACK, COLOR_WHITE } from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiAppBarDefaultProps = (): ComponentsProps["MuiAppBar"] =>
  getTestAutomationProps("app-bar") as ComponentsProps["MuiAppBar"];

export const getMuiAppBarComponent = (theme: Theme): Components["MuiAppBar"] => {
  const APPBAR_COLOR = {
    backgroundColor: COLOR_WHITE,
    color: COLOR_BLACK,
  };

  return {
    defaultProps: getTestAutomationProps("app-bar") as ComponentsProps["MuiAppBar"],
    styleOverrides: {
      root: APPBAR_COLOR,
      colorPrimary: APPBAR_COLOR,
      colorSecondary: APPBAR_COLOR,
      colorDefault: APPBAR_COLOR,
    },
  };
};
