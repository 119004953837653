import { BreakpointsOptions } from "@mui/material";

export const THEME_BREAKPOINTS: BreakpointsOptions = {
  keys: ["xs", "sm", "md", "lg", "xl"],
  values: {
    xs: 320,
    sm: 560,
    md: 1024,
    lg: 1153,
    xl: 1680,
  },
};
