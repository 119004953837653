import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import { getTestAutomationProps } from "~/utils/testUtils";

import {
  COLOR_BLACK,
  COLOR_FOCUSED,
  COLOR_GREYSCALE_BLACK_25,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_GREYSCALE_BLACK_8,
} from "../constants/colors";

export const getMuiRadioComponent = (theme: Theme): Components["MuiRadio"] => {
  const RADIO_COLOR = {
    "&, &:hover, &:focus, &:active": {
      opacity: 1,
      backgroundColor: "transparent !important",
    },
    "&.Mui-disabled	": {
      opacity: 1,
      color: COLOR_GREYSCALE_BLACK_25,
      backgroundColor: "transparent !important",
      "&:not(.Mui-checked)::before": {
        content: "''",
        position: "absolute",
        zIndex: -1,
        top: 4,
        right: 4,
        bottom: 4,
        left: 4,
        backgroundColor: COLOR_GREYSCALE_BLACK_8,
        borderRadius: "50%",
      },
    },
    "&, &:focus": {
      color: COLOR_BLACK,
    },
    "&:hover, &:active": {
      color: COLOR_GREYSCALE_BLACK_75,
    },
  };

  return {
    defaultProps: {
      color: "primary",
      ...getTestAutomationProps("radio"),
    },
    styleOverrides: {
      root: {
        margin: theme.spacing(1),
        padding: 0,
        height: 24,
        width: 24,
        "&.Mui-focusVisible:focus-within::before": {
          content: "''",
          display: "block",
          position: "absolute",
          top: -2,
          bottom: -2,
          left: -2,
          right: -2,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: COLOR_FOCUSED,
          borderRadius: "50%",
        },
      },
      colorPrimary: RADIO_COLOR,
      colorSecondary: RADIO_COLOR,
    },
  };
};
