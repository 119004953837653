import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import {
  COLOR_BUTTON_ACTIVE,
  COLOR_BUTTON_HOVER,
  COLOR_FOCUSED,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_PRIMARY_BLACK,
} from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiButtonGroupComponent = (
  theme: Theme
): Components["MuiButtonGroup"] => ({
  defaultProps: {
    disableElevation: true,
    disableFocusRipple: true,
    disableRipple: true,
    color: "primary",
    ...getTestAutomationProps("button-group"),
  },
  styleOverrides: {
    root: {
      marginTop: 0,
      borderRadius: 0,
      position: "relative",
      "&:focus-within:before": {
        content: "''",
        display: "block",
        position: "absolute",
        top: -6,
        left: -6,
        right: -6,
        bottom: -6,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: COLOR_FOCUSED,
      },
      "&:hover": {
        backgroundColor: COLOR_BUTTON_HOVER,
      },
      "&:active": {
        backgroundColor: COLOR_BUTTON_ACTIVE,
      },
      "&.Mui-disabled": {
        color: COLOR_PRIMARY_BLACK,
      },
    },
    contained: {
      backgroundColor: COLOR_PRIMARY_BLACK,
      "&:hover": {
        backgroundColor: COLOR_GREYSCALE_BLACK_75,
      },
      "&:active": {
        backgroundColor: COLOR_PRIMARY_BLACK,
      },
      "&.Mui-disabled": {
        backgroundColor: COLOR_PRIMARY_BLACK,
      },
    },
    groupedHorizontal: {
      "&:focus:before": {
        display: "none",
      },
      "&:not(:last-child)": {
        flexGrow: 1,
      },
      "&:last-child": {
        width: "auto",
      },
    },
    groupedContainedHorizontal: {
      "&:not(:last-child)": {
        borderRight: "none",
      },
      "&:last-child": {
        borderLeft: "2px solid white",
        marginLeft: -2,
      },
    },
    groupedContained: {
      "&, &:hover, &:active": {
        backgroundColor: "transparent",
      },
    },
    groupedOutlined: {
      "&, &:hover, &:active": {
        backgroundColor: "transparent",
      },
    },
  },
});
