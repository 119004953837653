import { ComponentsProps, Components } from "@mui/material/styles";

import { COLOR_GREYSCALE_BLACK_8 } from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiAccordionComponent = (): Components["MuiAccordion"] => ({
  defaultProps: {
    ...(getTestAutomationProps("accordion") as ComponentsProps["MuiAccordion"]),
    disableGutters: true,
  },
  styleOverrides: {
    root: {
      background: "none",
      boxShadow: "none",

      "&:last-child:after": {
        content: '""',
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        height: 1,
        backgroundColor: COLOR_GREYSCALE_BLACK_8,
      },

      "&.Mui-expanded": {
        margin: 0,
        "&:before": {
          opacity: 1,
        },
        "& + &:before": {
          display: "block",
          opacity: 1,
        },
      },

      "&.Mui-expanded	 + &": {
        "&:before": {
          display: "block",
        },
      },

      "&:first-of-type": {
        "&:before": {
          display: "block",
        },
      },

      "&:before": {
        backgroundColor: COLOR_GREYSCALE_BLACK_8,
      },
    },
  },
});
