import { Theme } from "@mui/material";
import { Components, ComponentsProps } from "@mui/material/styles";

import { COLOR_BLACK } from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiInputBaseComponent = (
  theme: Theme
): Components["MuiInputBase"] => ({
  defaultProps: getTestAutomationProps(
    "input-base"
  ) as ComponentsProps["MuiInputBase"],
  styleOverrides: {
    root: {
      height: 48,
      color: COLOR_BLACK,
    },
  },
});
