import React from "react";

import { Box, Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import { Down24 } from "~/theme/icons";
import { getTestAutomationProps } from "~/utils/testUtils";

const SelectArrow = () => {
  return (
    <Box
      sx={(theme) => ({
        height: 24,
        width: 24,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(-4),
        pointerEvents: "none",
      })}
    >
      <Down24 />
    </Box>
  );
};

export const getMuiSelectComponent = (theme: Theme): Components["MuiSelect"] => ({
  defaultProps: {
    IconComponent: SelectArrow,
    MenuProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    },
    ...getTestAutomationProps("select"),
  },
  styleOverrides: {
    select: {
      minHeight: "inherit",
      lineHeight: "24px",
    },
    outlined: {
      borderRadius: 0,
    },
  },
});
