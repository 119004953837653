import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";
import { CSSObject } from "@mui/styled-engine";

import {
  COLOR_BLACK,
  COLOR_BUTTON_ACTIVE,
  COLOR_BUTTON_HOVER,
  COLOR_FOCUSED,
  COLOR_GREYSCALE_BLACK_54,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
} from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

import { ARIAL_BLACK_FONT_FAMILY, FontWeights } from "../constants/typography";

export const getMuiButtonComponent = (theme: Theme): Components["MuiButton"] => {
  const CONTAINED_BUTTON: CSSObject = {
    fontFamily: ARIAL_BLACK_FONT_FAMILY,
    color: COLOR_PRIMARY_WHITE,
    backgroundColor: COLOR_PRIMARY_BLACK,
    "&, &.MuiButton-sizeLarge": {
      ...theme.typography.button,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
    },
    borderWidth: 0,
    "&:hover": {
      backgroundColor: COLOR_GREYSCALE_BLACK_75,
    },
    "&:active": {
      backgroundColor: COLOR_PRIMARY_BLACK,
    },
    "&.Mui-disabled": {
      opacity: 0.25,
      color: COLOR_PRIMARY_WHITE,
      backgroundColor: COLOR_PRIMARY_BLACK,
    },
    "& .MuiButton-startIcon	": {
      marginRight: theme.spacing(1.5),
    },
    "& .MuiButton-endIcon	": {
      marginLeft: theme.spacing(1.5),
    },
  };

  const OUTLINED_BUTTON: CSSObject = {
    fontFamily: ARIAL_BLACK_FONT_FAMILY,
    borderWidth: 0,
    borderStyle: "none",
    color: COLOR_PRIMARY_BLACK,
    borderColor: COLOR_PRIMARY_BLACK,
    backgroundColor: "transparent",
    "&, &.MuiButton-sizeLarge": {
      ...theme.typography.button,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: COLOR_BLACK,
    },
    "&:hover": {
      borderWidth: 0,
      borderColor: COLOR_PRIMARY_BLACK,
      color: COLOR_PRIMARY_BLACK,
      backgroundColor: COLOR_BUTTON_HOVER,
    },
    "&:active": {
      borderWidth: 0,
      borderColor: COLOR_PRIMARY_BLACK,
      color: COLOR_PRIMARY_BLACK,
      backgroundColor: COLOR_BUTTON_ACTIVE,
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      borderWidth: 0,
      color: COLOR_PRIMARY_BLACK,
      borderColor: COLOR_PRIMARY_BLACK,
    },
  };

  const TEXT_BUTTON: CSSObject = {
    fontFamily: ARIAL_BLACK_FONT_FAMILY,
    height: 40,
    color: COLOR_PRIMARY_BLACK,
    backgroundColor: "transparent",
    textTransform: "none",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    textDecoration: "none",

    ...theme.typography.body1,

    "& .MuiButton-startIcon	": {
      marginRight: theme.spacing(1),
    },
    "& .MuiButton-endIcon	": {
      marginLeft: theme.spacing(1),
    },
    "&:hover": {
      backgroundColor: COLOR_BUTTON_HOVER,
    },
    "&:active": {
      backgroundColor: COLOR_BUTTON_ACTIVE,
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      color: COLOR_PRIMARY_BLACK,
      backgroundColor: "transparent",
    },
    "&.MuiButton-sizeLarge": {
      height: 48,
    },
  };

  return {
    defaultProps: {
      disableElevation: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
      disableRipple: true,
      color: "primary",
      ...getTestAutomationProps("button"),
    },
    styleOverrides: {
      root: {
        height: 48,
        borderRadius: 0,
        minWidth: "auto",
        transition: "none",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        ...theme.typography.button,

        "&.Mui-focusVisible:before": {
          content: "''",
          display: "block",
          position: "absolute",
          top: -6,
          bottom: -6,
          left: -6,
          right: -6,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: COLOR_FOCUSED,
        },
        "&.alignLeft": {
          justifyContent: "left",
        },
        "&.alignLeft .MuiButton-endIcon	": {
          marginLeft: "auto",
        },

        "&.underlined": {
          textDecoration: "underline",
        },

        // links
        "&.body1": {
          ...theme.typography.body1,
        },
        "&.body2": {
          ...theme.typography.body2,
        },
        "&.body1, &.body2": {
          margin: 0,
          padding: 0,
          cursor: "pointer",
          color: COLOR_BLACK,
          backgroundColor: "transparent",
          height: 24,
          lineHeight: "24px",
          "& > strong": {
            fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
          },
          "& .MuiButton-startIcon	": {
            marginRight: theme.spacing(1),
          },
          "& .MuiButton-endIcon	": {
            marginLeft: theme.spacing(1),
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:active": {
            backgroundColor: "transparent",
          },
          "&:disabled": {
            opacity: 1,
            color: COLOR_GREYSCALE_BLACK_54,
            backgroundColor: "transparent",
          },
          "&:focus": {
            backgroundColor: "transparent",
            "&:before": {
              top: -2,
              bottom: -2,
              left: -2,
              right: -2,
            },
          },
          "&.underlined": {
            textDecoration: "underline",
          },
        },
      },

      sizeLarge: {
        height: 56,
      },

      contained: {
        fontFamily: ARIAL_BLACK_FONT_FAMILY,
        color: COLOR_PRIMARY_BLACK,
        backgroundColor: "transparent",
        "&, &.MuiButton-sizeLarge": {
          ...theme.typography.button,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          fontWeight: theme.typography.fontWeightBold,
        },
        "&:hover": {
          backgroundColor: COLOR_BUTTON_HOVER,
        },
        "&:active": {
          backgroundColor: COLOR_BUTTON_ACTIVE,
        },
        "&.Mui-disabled": {
          opacity: 0.5,
          color: COLOR_PRIMARY_BLACK,
          backgroundColor: "transparent",
        },
        "& .MuiButton-startIcon	": {
          marginRight: theme.spacing(1),
        },
        "& .MuiButton-endIcon	": {
          marginLeft: theme.spacing(1),
        },
      },
      containedPrimary: CONTAINED_BUTTON,
      containedSecondary: CONTAINED_BUTTON,

      outlined: OUTLINED_BUTTON,
      outlinedPrimary: OUTLINED_BUTTON,
      outlinedSecondary: OUTLINED_BUTTON,

      text: TEXT_BUTTON,
      textPrimary: TEXT_BUTTON,
      textSecondary: TEXT_BUTTON,

      startIcon: {
        marginRight: theme.spacing(1.5),
        marginLeft: 0,
      },
      endIcon: {
        marginLeft: theme.spacing(1.5),
        marginRight: 0,
      },
    },
  };
};
