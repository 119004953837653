import { Theme } from "@mui/material";
import { Components, ComponentsProps } from "@mui/material/styles";

import { COLOR_GREYSCALE_BLACK_75 } from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiFormHelperTextComponent = (
  theme: Theme
): Components["MuiFormHelperText"] => ({
  defaultProps: getTestAutomationProps(
    "form-helper-text"
  ) as ComponentsProps["MuiFormHelperText"],
  styleOverrides: {
    contained: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: theme.spacing(0.5),
      color: COLOR_GREYSCALE_BLACK_75,
      ...theme.typography.body2,
    },
  },
});
