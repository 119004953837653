import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import { getTestAutomationProps } from "~/utils/testUtils";

import { getFormatMedia } from "../utils/formatMedia";

export const getMuiContainerComponent = (
  theme: Theme
): Components["MuiContainer"] => {
  const formatMedia = getFormatMedia(theme);

  return {
    defaultProps: {
      maxWidth: false,
      ...getTestAutomationProps("container"),
    },
    styleOverrides: {
      root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        maxWidth: 1920,
        [formatMedia.BREAKPOINT_TABLET]: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
        [formatMedia.BREAKPOINT_LARGE_DESKTOP]: {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
        },
      },
    },
  };
};
