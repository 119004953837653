import { Theme } from "@mui/material";
import { Components } from "@mui/material/styles";

import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiTextFieldComponent = (
  theme: Theme
): Components["MuiTextField"] => ({
  defaultProps: {
    variant: "outlined",
    SelectProps: {
      MenuProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      },
    },
    ...getTestAutomationProps("text-field"),
  },
});
