import { Components, Theme } from "@mui/material/styles";

import { getTestAutomationProps } from "~/utils/testUtils";

export const getMuiLinkComponent = (theme: Theme): Components["MuiLink"] => ({
  defaultProps: {
    underline: "always",
    ...getTestAutomationProps("link"),
  },
  styleOverrides: {
    root: {
      cursor: "pointer",
      color: "inherit",
      fontWeight: "inherit",
      fontStyle: "inherit",
    },
  },
});
